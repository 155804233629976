import request from '@/utils/request'

export function search (params) {
  return request({
    url: '/prosa/file/search',
    method: 'post',
    params
  })
}

export function getPage (page, noitems, params) {
  return request({
    url: '/prosa/file/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function uploadFile (data) {
  return request({
    url: '/prosa/upload',
    method: 'post',
    data
  })
}

export function getRecords (key) {
  return request({
    url: '/prosa/records/' + key,
    method: 'get'
  })
}

export function cancel (key) {
  return request({
    url: '/prosa/cancel/' + key,
    method: 'get'
  })
}

export function process (key) {
  return request({
    url: '/prosa/process/' + key,
    method: 'get'
  })
}

export function filterData (params) {
  return request({
    url: '/prosa/file/filterData',
    method: 'post',
    params
  })
}

export function exportExcel (data) {
  return request({
    url: '/prosa/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
