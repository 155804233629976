<template>
  <div class="app-container">
    <el-row type="flex">
        <el-descriptions class="margin-top" title="Pagos procesados" :column="5">
            <el-descriptions-item>
                <template slot="label">
                    <el-tag size="small">
                        <i class="el-icon-tickets"></i>
                        Total
                    </el-tag>
                </template>
                {{ total }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <el-tag type="danger" size="small">
                        <i class="el-icon-document-delete"></i>
                        Inprocesables
                    </el-tag>
                </template>
                {{ errors }}
            </el-descriptions-item>
        </el-descriptions>
    </el-row>
    <el-row>
      <el-col>
          <el-button
            @click.stop.prevent="handleExport"
            type="success"
            icon="el-icon-bottom"
            size="mini"
          >Exportar</el-button>
        </el-col>
    </el-row>
    <el-table
      size="mini"
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      height="600"
      fit
      border
      highlight-current-row
      style="width: 100%"
    >
     <el-table-column label="ID" width="130" align="center">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{scope.row._id}}</div>
            <span>{{ scope.row._id.toString().substr(-4) }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="Id Familia" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.idFamilyJR }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre Familia" width="250" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.familyName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Sucursal" width="170" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.location }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Monto" width="150" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.amount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Motivo Pago" width="150" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.paymentType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Tarjeta" width="150" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.card }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Estatus" width="110" align="center">
        <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 'initialized'" size="small"> {{ scope.row.status }} </el-tag>
            <el-tag v-if="scope.row.status == 'completed'" type="success" size="small"> {{ scope.row.status }} </el-tag>
            <el-tag v-else type="danger" size="small"> {{ scope.row.status }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Errores" width="250" align="center">
        <template slot-scope="scope">
            <span>{{ scope.row.errors }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Operación" width="150" fixed="right" align="center">
        <template slot-scope="scope">
          <el-button @click="handleSearchPayment(scope.row._id)" type="primary" size="mini">
            <span>Ver Pago</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Fecha de carga" width="150" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at | formatDateTime }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="center">
        <el-button @click="handleCancel()" type="danger">
            <span>Regresar</span>
        </el-button>
    </el-row>
  </div>
</template>

<script>
import { getRecords, exportExcel } from '@/api/prosa'

export default {
  data () {
    return {
      list: null,
      loading: false,
      sizePerPage: 25,
      locations: null,
      key: null
    }
  },
  mounted () {
    this.getQuery()
    this.handleGetRecords()
  },
  methods: {
    getQuery () {
      if (this.$route.query !== undefined || this.$route.query !== null) {
        this.key = this.$route.query.key
      }
    },
    async handleGetRecords () {
      this.loading = true
      await getRecords(this.key)
        .then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.errors = response.data.unprocessables
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchPayment (id) {
      this.$router.push({ name: 'payments-store', query: { id: id } })
    },
    async handleCancel () {
      this.$router.push({ name: 'prosa' })
    },
    async handleExport () {
      this.loading = true
      var formData = new FormData()
      var _ids = []
      this.list.forEach(element => {
        _ids.push(element._id)
      })
      formData.append('_id', _ids)
      await exportExcel(
        formData
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'Reporte-de-pagos-Procesados-' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>
